import React, { useEffect, useState } from "react"
import MainLayout from "../layouts/MainLayout"
import { getQueryParams } from "../helpers/getQueryParams"
import { FactionDataService } from "../services/FactionDataService"
import * as styles from "./litepaper.module.scss"

export default function Litepaper() {
  const [faction, setFaction] = useState()

  useEffect(() => {
    const factionDataService = new FactionDataService()

    const params = getQueryParams(window.location.href)
    if (Object.keys(params).length > 0) {
      setFaction(factionDataService.get(params.type))
    }
  }, [])

  return (
    <MainLayout navCompact={true}>
      {faction ? (
        <div
          style={{
            minHeight: "100vh",
            backgroundColor: "#1f2032",
            paddingTop: "10rem",
          }}
          className={styles.container}
        >
          <div className="container">
            <div className="row">
              <div className="d-flex col-lg-12 justify-content-center">
                <iframe
                  src={faction.litepaperUrl}
                  name={`${faction.name} faction litepaper`}
                  scrolling="no"
                  frameBorder="0"
                  style={{ border: "1px solid #AABBCC" }}
                  allowFullScreen={true}
                  mozallowfullscreen="true"
                  webkitallowfullscreen="true"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </MainLayout>
  )
}
